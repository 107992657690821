<template>
  <div>
    <b-card title="Vous pouvez rechercher les logistics directement ici.">
      <b-row>
        <b-col
          cols="6"
        >
          <div class="px-2 py-1 navbar-light header-navbar rounded-lg shadow d-flex align-items-center justify-content-start">
            <feather-icon
              icon="SearchIcon"
              class="mr-50 text-primary"
              size="20"
            />
            <b-input-group class="input-group-merge shadow-none">
              <b-form-input
                v-model="code"
                placeholder="Rechercher un sku..."
                style="height: auto;"
                class="border-0 shadow-none"
                @input="filter"
              />
            </b-input-group>
          </div>
        </b-col>
        <b-col
          cols="6"
          md="2"
          class="mt-2 mt-md-0"
        >
          <v-select
            v-model="logisticien"
            placeholder="Fabricant"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="logisticOptions"
            @input="filter"
          />
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col
          v-if="logistics && logistics.length"
          cols="2"
        >
          <download-csv
            :data="logistics.map(l => ({sku: l.sku, type: l.alert ? 'A COMMANDER' : 'STOCK SUFFISANT', stock: (l.agediss + l.colisland) - l.waiting, logisticien: l.logisticien, 'M-3': l.quantityOrder, 'M-6': l.quantityOrderM6, 'Y - 1 / M + 6': l.quantityOrderOld, 'Y - 1 / M - 3': l.quantityOrderOldSame, orderHistoric: l.orderHistoric && l.orderHistoric.length ? l.orderHistoric.map(l => `${$moment(l.date).format('DD/MM/YYYY')}: ${l.quantity}`).join(' - '): '' }))"
            name="logistic.csv">
            <b-button class="mb-2" variant="warning">Télécharger</b-button>
          </download-csv>
        </b-col>
      </b-row>
      <div v-if="logisticOrder.length">
        <strong><u>Référence dans le BL:</u></strong>
        <ul>
          <li v-for="(ref, key) of logisticOrder" :key="key">
            <strong>{{ ref.sku }}:</strong> {{ ref.quantity }} ( <strong><u>Livraison prévue :</u></strong> {{ $moment(ref.date).format('DD/MM/YYYY') }})
            <feather-icon
              size="16"
              icon="Trash2Icon"
              class="mr-50 text-warning cursor-pointer"
              @click="logisticOrder.splice(key, 1);"
            />
          </li>
        </ul>
        <download-csv
          :data="logisticOrder"
          name="bl.csv">
          <b-button class="mb-2" variant="warning" @click="updateLogistic">Générer le BL</b-button>
        </download-csv>
      </div>

      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner label="Loading..." />
      </div>
      <b-row v-else class="match-height">
        <b-table
          ref="selectableTable"
          small
          selectable
          :items="logistics"
          :fields="fields"
        >
          <template #cell(show_details)="data">
            <b-form-checkbox
              v-model="data.detailsShowing"
              plain
              class="vs-checkbox-con"
              @change="data.toggleDetails"
            >
              <span class="vs-checkbox">
                <span class="vs-checkbox--check">
                  <i class="vs-icon feather icon-check" />
                </span>
              </span>
              <span class="vs-label">{{ data.detailsShowing ? 'Cacher' : 'Voir' }}</span>
            </b-form-checkbox>
          </template>
          <template #cell(sku)="data">
            <p>{{ data.item.sku }} <br><span>{{ data.item.product }}</span></p>
          </template>
          <!-- <template #cell(order)="data">
            <p>{{ data.item.orderHistoric && data.item.orderHistoric.length ? data.item.orderHistoric.reduce((a, b) => (a.quantity || 0) + (b.quantity || 0) ) : '' }}</p>
          </template> -->
          <template #cell(alert)="data">
            <b-badge :variant="`light-${data.item.alert ? 'danger' : 'success'}`">
              {{ data.item.alert ? 'CHECK' : 'OK' }}
            </b-badge>
          </template>
          <template #cell(total)="data">
            <b-badge :variant="`light-${data.item.total < 5 ? 'danger' : 'success'}`">
              {{ (data.item.agediss + data.item.colisland) - data.item.waiting }}
            </b-badge>
          </template>
          <template #cell(quantite)="data">
            <b-form-input
              v-model="data.item.quantity"
              type="number"
              placeholder="Quantité commandée"
              class="border-0 shadow-none  w-50"
            />
          </template>
          <template #cell(date)="data">
            <flat-pickr
              v-model="data.item.date"
              placeholder="Date"
              class="form-control w-75"
            />
          </template>
          <template #cell(dateOrder)="data">
            <flat-pickr
              v-model="data.item.dateOrder"
              placeholder="Date de commande"
              class="form-control w-75"
            />
          </template>
          <template #cell(X)="data">
            <feather-icon
              v-if="!logisticOrder.find(l => l._id === data.item._id)"
              size="16"
              icon="CheckIcon"
              class="mr-50 text-warning"
              @click="logisticOrder.push(data.item)"
            />
          </template>
          <template #row-details="data">
            <p><strong><u>Historique des commandes:</u></strong></p>
            <b-table-lite
              responsive
              :items="data.item.orderBl"
              :fields="['id', 'sku', 'description', 'quantity', 'received', 'remaining', {key:'date', 'label': 'Date Order'}, 'dateShipping', 'dateReceived', 'price']"
            >
              <template #cell(date)="data">
                {{ $moment(data.item.date).format('DD/MM/YYYY') }}
              </template>
              <template #cell(dateShipping)="data">
                {{ $moment(data.item.dateShipping).format('DD/MM/YYYY') }}
              </template>
              <template #cell(dateReceived)="data">
                {{ data.item.dateReceived }}
              </template>
              <template #cell(received)="data">
                {{ data.item.quantity - data.item.remaining }}
              </template>
            </b-table-lite>
            <ul>
              <!-- <li v-for="(ordered, keyH) of data.item.orderBl" :key="keyH"> -->
                <!-- <div class="row mb-1 align-items-center">
                  <b-col class="col-1">
                    <b-form-input
                      v-model="ordered.quantity"
                      placeholder="Quantité commandé"
                      style="height: auto;"
                      class="shadow-none"
                    />
                  </b-col>
                  <b-col class="col-2" style="padding-right: 0;">
                    unités commandées le
                  </b-col>
                  <b-col class="col-2">
                    <flat-pickr
                      v-model="ordered.date"
                      placeholder="Date"
                      class="form-control"
                    />
                  </b-col>
                  <b-col class="col-3 d-flex">
                    Déjà recu ?
                    <b-form-input
                      v-model="ordered.received"
                      placeholder="Quantité déjà recu"
                      style="height: auto;"
                      class="shadow-none w-50 ml-1"
                    />
                  </b-col>
                  <b-col class="col-2">
                    <flat-pickr
                      v-model="ordered.lastDateReceived"
                      placeholder="Date de dernière réception"
                      class="form-control"
                    />
                  </b-col>
                  <b-col class="col-1">
                    <feather-icon
                      icon="TrashIcon"
                      size="20"
                      @click="data.item.orderHistoric.splice(keyH, 1) && saveOrder(data.item)"
                    />
                    <feather-icon
                      class="ml-1 text-primary"
                      icon="SaveIcon"
                      size="20"
                      @click="saveOrder(data.item)"
                    />
                  </b-col>
                </div> -->
              <!-- </li> -->
            </ul>
          </template>
        </b-table>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BSpinner,
  BFormInput,
  BInputGroup,
  BBadge,
  BTable,
  VBPopover,
  BButton,
  BFormCheckbox,
  BTableLite,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import JsonCSV from 'vue-json-csv'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BFormInput,
    BCard,
    BSpinner,
    BRow,
    BCol,
    BTable,
    BBadge,
    BInputGroup,
    BButton,
    vSelect,
    flatPickr,
    BFormCheckbox,
    BTableLite,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,
    'download-csv': JsonCSV,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      required,
      baseLogistics: [],
      logistics: [],
      products: [],
      categories: [],
      logistic: {},
      conditionalProducts: '',
      conditionalCategories: '',
      logisticOptions: ['Dequecker', 'GommaGomma', 'Valrupt', 'Samarfil', 'Sabbe', 'Radi', 'Homespirit', 'Drouault', 'Cogal', 'Abeil'],
      types: [{ name: 'Remise fixe', type: 'fixed_cart' }, { name: 'Remise en pourcentage', type: 'percent' }],
      individualType: [{ name: 'Oui', type: true }, { name: 'Non', type: false }],
      conditions: [{ name: 'OU', type: 'or' }, { name: 'ET', type: 'and' }],
      code: '',
      fields: [{ key: 'show_details', label: 'Historique' }, { key: 'sku', sortable: true }, { key: 'total', label: 'Stock', sortable: true }, { key: 'logisticien', sortable: true }],
      showModal: false,
      categoryInformations: [],
      logisticOrder: [],
      loading: false,
    }
  },
  async mounted() {
    try {
      await this.getLogistics()
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    async saveOrder(item) {
      try {
        await this.$http.put(`/admin/logistic/${item._id}`, { item })
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Enregistrement réussi.',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      } catch (err) {
        console.log(err)
      }
    },
    async getLogistics() {
      try {
        const {
          logistics,
        } = await this.$http.get('/admin/logistic/order')
        this.logistics = logistics
        this.baseLogistics = logistics
        this.loading = false
      } catch (err) {
        console.log(err)
      }
    },
    async updateLogistic() {
      try {
        await this.$http.post('/admin/logistic/bl-historic', { logs: this.logisticOrder })
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Enregistrement effectué',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.logisticOrder = []
        this.getLogistics()
      } catch (err) {
        console.log(err)
      }
    },
    filter() {
      this.filterQuery = this.code
      if ((!this.code || this.code === '') && (!this.logisticien || this.logisticien === '')) {
        this.logistics = this.baseLogistics
      } else {
        const value = this.code
        if (this.logisticien) {
          this.logistics = this.baseLogistics.filter(logistic => logistic.sku?.toLowerCase().includes(value.toLowerCase()) && this.logisticien === logistic.logisticien)
        } else {
          this.logistics = this.baseLogistics.filter(logistic => logistic.sku?.toLowerCase().includes(value.toLowerCase()))
        }
      }
    },
  },
}
</script>

<style>
 .jsoneditor-poweredBy {
   display: none;
 }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
